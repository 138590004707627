import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BTModalWindow } from '../shared/bt-modal-window'
import { useArchiveProject } from 'hooks/project/useArchive'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { useCurrentProject, useActionAtEnterKey } from 'hooks'
import { toastNotifications } from 'helpers/toastNotifications'
import { getContentByType } from './bt-edit-project-settings.helpers'
import { CreateProjectSchema } from '../bt-create-project-form/bt-create-project-form'
import { setHeaderClientName, setHeaderTitle, setHeaderDeadlineTime } from 'redux/HeaderSlice'
import { BTEditProjectSettingsWrapper, BTArchiveModal } from './bt-edit-project-settings.presets'
import { IMemberListItem } from '../shared/bt-project-add-members-step/components/bt-member-list-item'
import {
  IBTProjectSettingFormProps,
  IBTEditProjectSettingsProps,
} from './bt-edit-project-settings.props'
import {
  ProjectRole,
  ProjectFragment,
  ProjectFragmentDoc,
  useUpdateProjectMutation,
  useProjectSummariesQuery,
  useDuplicateProjectMutation,
} from 'generated/graphql'

export const BTEditProjectSettings: FC<IBTEditProjectSettingsProps> = ({
  isOpened,
  handleModalClose,
  type = 'all',
}) => {
  const [changedMembers, setChangedMembers] = useState<IMemberListItem[]>([])
  const [members, setMembers] = useState<IMemberListItem[]>([])
  const [archiveLoading, setArchiveLoading] = useState(false)
  const [archiveModalOpen, setArchiveModalOpen] = useState(false)

  const { data: projects, refetch: projectsRefetch } = useProjectSummariesQuery()
  const [duplicateProject, { loading }] = useDuplicateProjectMutation()
  const [updateProjectMutation] = useUpdateProjectMutation()
  const { project: currentProject } = useCurrentProject()
  const onEnterKey = useActionAtEnterKey()
  const dispatch = useAppDispatch()
  const { id } = useParams()

  const {
    title,
    deadlineTime,
    clientName: selectorClientName,
  } = useAppSelector((state) => state.header)

  const archive = useArchiveProject()
  const navigate = useNavigate()

  const openArchiveModal = () => {
    setArchiveModalOpen(true)
  }
  const closeArchiveModal = () => {
    setArchiveModalOpen(false)
  }

  const archiveHandler = async () => {
    setArchiveLoading(true)
    await archive()
    setArchiveLoading(false)
    setArchiveModalOpen(false)
    handleModalClose()
    navigate('/workspace')
    toastNotifications('Project archived successfully').success()
  }

  const isMemberExist = (memberId: string) => {
    const memberIndex = changedMembers.findIndex((elem) => elem.id === memberId)
    return memberIndex !== -1
  }

  const changeMemberData = (member: IMemberListItem) => {
    if (isMemberExist(member.id)) {
      setChangedMembers((prevState) =>
        prevState.map((elem) =>
          elem.id === member.id ? { ...elem, projectRole: member.projectRole } : elem
        )
      )
    } else {
      setChangedMembers((prevState) => [...prevState, member])
    }
  }

  useEffect(() => {
    if (currentProject) {
      const allProjectMemebrs =
        currentProject?.members.map((member) => ({
          avatarUrl: member.user.avatarUrl!,
          id: member.user.id!,
          name: member.user.name!,
          projectRole: member.role,
        })) || []
      setMembers(allProjectMemebrs.filter(({ projectRole }) => projectRole !== ProjectRole.None))
    }
  }, [currentProject])

  const handleSubmit = async ({
    clientName,
    dueDate,
    projectName,
    organization,
  }: IBTProjectSettingFormProps) => {
    if (id !== undefined) {
      const resp = await updateProjectMutation({
        variables: {
          input: {
            id,
            clientName,
            name: projectName,
            dueDate,
            members: changedMembers.map(({ id: userId, projectRole }) => ({
              userId,
              role: projectRole,
            })),
            organizationId: organization,
          },
        },
        update: (cache, { data }) => {
          cache.updateFragment(
            {
              id: `Project:${id}`,
              fragment: ProjectFragmentDoc,
              fragmentName: 'Project',
            },
            () => {
              const fragment = cache.readFragment({
                id: `Project:${id}`,
                fragment: ProjectFragmentDoc,
                fragmentName: 'Project',
              }) as ProjectFragment

              return {
                ...fragment,
                name: data?.updateProject.project.name,
                client: {
                  __typename: 'Client',
                  name: data?.updateProject.project.client?.name,
                },
                dueDate: data?.updateProject.project.dueDate,
                members: data?.updateProject.project.members,
              }
            }
          )
        },
      })

      dispatch(setHeaderClientName(resp?.data?.updateProject.project.client?.name || 'Client name'))
      dispatch(setHeaderTitle(resp?.data?.updateProject.project.name || 'Project name'))
      dispatch(setHeaderDeadlineTime(resp?.data?.updateProject.project.dueDate || ''))

      handleModalClose()
    }
  }

  const formik = useFormik({
    validationSchema: CreateProjectSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      clientName: selectorClientName,
      projectName: title,
      dueDate: deadlineTime,
      organization: '',
    },
    initialErrors: {},
  })

  onEnterKey(formik.handleSubmit)

  const duplicateHandler = async () => {
    if (id) {
      await duplicateProject({
        variables: {
          input: { projectId: id },
        },
      })

      await projectsRefetch()
      toastNotifications(`This project has been duplicated as ${title} 2 in the side bar`).success()
      handleModalClose()
    }
  }

  useEffect(() => {
    formik.setFieldValue('clientName', selectorClientName)
    formik.setFieldValue('projectName', title)
    formik.setFieldValue('dueDate', deadlineTime)
    formik.setFieldValue('organization', currentProject?.organization.id || '')
  }, [isOpened])

  const Content = getContentByType(type, formik, { members, setMembers }, changeMemberData)

  return (
    <BTModalWindow isOpened={isOpened} handleClose={handleModalClose}>
      <form onSubmit={formik.handleSubmit}>
        <BTEditProjectSettingsWrapper>
          <div className="main-block">{Content}</div>
          <LoadingButton
            variant="contained"
            className="submit-button"
            type="submit"
            loading={formik.isSubmitting}
            disabled={!formik.isValid}
          >
            Save
          </LoadingButton>

          {type === 'details' && (
            <div className="secondary-buttons-block">
              <LoadingButton
                className="secondary-button"
                disabled={loading}
                onClick={openArchiveModal}
              >
                Archive
              </LoadingButton>

              <LoadingButton
                className="secondary-button"
                disabled={loading}
                onClick={duplicateHandler}
              >
                Duplicate
              </LoadingButton>
            </div>
          )}
        </BTEditProjectSettingsWrapper>
      </form>

      <BTModalWindow isOpened={archiveModalOpen} handleClose={closeArchiveModal}>
        <BTArchiveModal>
          <div className="archive-modal-title">Are you sure?</div>
          <div className="archive-modal-text">
            Your project will be removed from your dashboard and tasks. You will be able to find it
            and recover it from the archived tab on your dashboard.
          </div>
          <LoadingButton
            variant="contained"
            className="archive-modal-button "
            type="button"
            loading={archiveLoading}
            onClick={archiveHandler}
          >
            Archive Project
          </LoadingButton>
        </BTArchiveModal>
      </BTModalWindow>
    </BTModalWindow>
  )
}
