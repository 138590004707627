import { useAppDispatch } from 'redux/store'
import { FC, useState, useEffect, MouseEvent } from 'react'
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api'
import { ActivityComposePage } from 'layouts/activity-compose-page'
import { BTLoader } from '../../shared/bt-loader'
import { useFileDownload } from '../../../hooks/file/useFileDownload'
import { useGetDeliverablesQuery } from '../../../generated/graphql'
import { BTFileView } from './components/bt-file-view'
import { BTBriefDocument } from '../bt-brief-document/bt-brief-document'
import { BriefComposePage } from '../../../layouts/brief-compose-page'
import { BriefCrashPage } from '../../../layouts/brief-compose-page/brief-crash-page'
import { BTSetBriefProps, BRIEF_TYPES } from './bt-set-brief.props'
import { toastNotifications } from '../../../helpers/toastNotifications'
import { setupBriefId } from '../../../redux/BriefCreationSlice'
import { fileConvert, IFileFromConvertor } from '../../../helpers/file/fileConvert'
import { useGetComposeDocument } from '../../../hooks/compose/useGetComposeDocument'

export const BTSetBrief: FC<BTSetBriefProps> = ({ briefData }) => {
  const [briefType, setBriefType] = useState<BRIEF_TYPES>()
  const [briefFile, setBriefFile] = useState<IFileFromConvertor[] | null>(null)
  const [briefFileForDownload, setBriefFileForDownload] = useState<Blob | undefined>()
  const [briefCompose, setBriefCompose] = useState<string | null>()
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const [numPages, setNumPages] = useState<number | null>(null)
  const [pageNumber, setPageNumber] = useState(1)

  const fileDownload = useFileDownload()
  const composeDownload = useGetComposeDocument()
  const dispatch = useAppDispatch()
  const { data: templateData, refetch } = useGetDeliverablesQuery({
    variables: { briefId: briefData?.getBrief.id },
  })

  const onDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
    setNumPages(pdf.numPages)
  }

  /** Upload brief logic */
  const getUpload = async () => {
    setDataLoading(true)
    try {
      const briefUploadedDocumentId = briefData?.getBrief?.uploadedDocument?.id

      const response = await fileDownload(briefUploadedDocumentId)

      const fileToconvert = {
        name: briefData?.getBrief.uploadedDocument?.name,
        content: response,
      }

      fileConvert([fileToconvert], setBriefFile)
      setBriefFileForDownload(response)
      setDataLoading(false)
    } catch (err) {
      const error = err as Error
      toastNotifications(error.message).error()
    }
  }

  const pagesHandler = (e: MouseEvent<HTMLButtonElement>) => {
    switch (e.currentTarget.id) {
      case 'next':
        setPageNumber(pageNumber + 1)
        break
      case 'prev':
        setPageNumber(pageNumber - 1)
        break

      default:
        break
    }
  }

  /** Compose document logic */
  const getComposedDocument = async () => {
    setDataLoading(true)
    try {
      const briefComposedDocumentId = briefData?.getBrief?.composedDocument?.id
      const response = await composeDownload(briefComposedDocumentId)
      if (response) {
        const text = `${response}`
        setBriefCompose(text)
        setDataLoading(false)
      }
    } catch (err) {
      setDataLoading(false)
      const error = err as Error
      toastNotifications(error.message).error()
    }
  }

  /** Compose template logic */
  const getComposeTemplate = () => {
    dispatch(setupBriefId(briefData?.getBrief.id))

    if (templateData?.getDeliverables.length) {
      setDataLoading(false)
    } else if (briefData?.getBrief.id) {
      refetch({ briefId: briefData?.getBrief.id }).then((refetchedData) => {
        if (refetchedData.data) {
          setDataLoading(false)
        }
      })
    }
  }

  useEffect(() => {
    if (briefData.getBrief.uploadedDocument) {
      getUpload()
      setBriefType(BRIEF_TYPES.UPLOAD)
    }

    if (briefData.getBrief.composedDocument) {
      getComposedDocument()
      setBriefType(BRIEF_TYPES.COMPOSE)
    }
  }, [briefType])

  useEffect(() => {
    if (templateData?.getDeliverables) {
      getComposeTemplate()
      setBriefType(BRIEF_TYPES.TEMPLATE)
    }
  }, [templateData?.getDeliverables])

  const renderBrief = () => {
    console.log('briefType', briefType)
    switch (briefType) {
      case BRIEF_TYPES.UPLOAD:
        return (
          <ActivityComposePage>
            <BTFileView
              file={briefFile}
              pageNumber={pageNumber}
              numPages={numPages}
              onLoadSuccess={onDocumentLoadSuccess}
              pagesHandler={pagesHandler}
              fileForDownload={briefFileForDownload}
            />
          </ActivityComposePage>
        )

      case BRIEF_TYPES.COMPOSE:
        return (
          <ActivityComposePage>
            <BTBriefDocument initialText={briefCompose} />
          </ActivityComposePage>
        )

      case BRIEF_TYPES.TEMPLATE:
        return <BriefComposePage dataFromProps={templateData} />

      default:
        /** @desc way to give a chance to user to delete old deliverables type template */
        return <BriefCrashPage />
      // return <div />
    }
  }

  return dataLoading ? <BTLoader /> : renderBrief()
}
