import { colors } from 'theme/Colors'
import styled from 'styled-components'

export const BTEditProjectSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .main-block {
    display: flex;
    flex-direction: row;
    padding-top: 50px;
  }

  .submit-button {
    margin-top: 30px;
    margin-bottom: 20px;

    width: 240px;

    padding: 10px;

    font-family: Poppins;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-transform: capitalize;
  }

  .secondary-buttons-block {
    width: 240px;
    display: flex;
    justify-content: space-between;

    .secondary-button {
      width: 48%;

      border: 1px solid ${colors.projectColors.iconDarkGrey};
      border-radius: 5px;
      padding: 10px;

      ${({ theme }: any) => ({ ...theme.typography.regular })};
      color: ${colors.projectColors.iconDarkGrey};
      text-transform: none;
    }
  }

  .form-block {
    .inputs-wrapper {
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 0px;
    }

    .MuiButton-contained {
      display: none;
    }

    span {
      text-align: center;
      padding-bottom: 20px;
    }
  }

  .divider {
    height: auto;
    width: 1px;
    background-color: ${colors.navyGrey[300]};
  }

  .assignee-block {
    padding-right: 50px;
    padding-left: 50px;

    .MuiFormControl-root {
      width: 240px;
    }

    .MuiButtonGroup-root {
      display: none;
    }
  }
`

export const BTArchiveModal = styled.div`
  width: 240px;

  .archive-modal-title {
    ${({ theme }: any) => ({ ...theme.typography.h5 })};
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .archive-modal-text {
    ${({ theme }: any) => ({ ...theme.typography.regular })};
    margin-bottom: 30px;
  }

  .archive-modal-button {
    ${({ theme }: any) => ({ ...theme.typography.subtitle1 })};
    width: 240px;
    margin-top: 30px;
    margin-bottom: 20px;
    text-transform: capitalize;
  }
`
